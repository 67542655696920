import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const OnArrivalSection = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">On arrival</h1>
        <p className="text-base font-semibold lg:text-2xl">No app, no sign up required by the driver.</p>
        <p className="text-base font-semibold lg:text-2xl">Scan and get started.</p>
      </div>
      <div className="flex flex-col max-w-full mt-8 lg:max-w-screen-lg lg:mx-auto">
        <StaticImage 
          placeholder="tracedSVG"
          src="../images/queuing.png" 
          alt="Your destination with peace of mind for vehicle charging"/>
      </div>
      <div className="py-10 text-black bg-relay-green-background lg:py-14">
        <section className="flex flex-col max-w-screen-lg mx-4 lg:mx-auto lg:flex-row">
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
                <h2 className="text-3xl font-bold text-relay-green-secondary">Available to charge</h2>
                <p className="my-2 text-base-lg">
                  When your guests plug in and charge, they tell us how long they will be.
                  Once they leave, we let others know that there's a free spot. In case they forget
                  we tap them to check if they are still there.
                </p>
            </div>
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
              <h2 className="text-3xl font-bold text-relay-green-secondary">Waiting to charge</h2>
                <p className="my-2 text-base-lg">
                  Arrive to find someone already charging? Your guests can scan in and securely
                  leave their details with us. We will keep tabs on when there's a free spot and 
                  let the next person know.
                </p>
            </div>
            <div className="lg:w-1/3">
                <h2 className="text-3xl font-bold text-relay-green-secondary">Unable to charge</h2>
                <p className="my-2 text-base-lg">
                  Don't let your guests get disappointed by a non-operational charger.
                  Your guests can report the charger. The system already knows the charger they are at.
                  We ask them all the relevant details you need to help get charging again.
                </p>
            </div>
          </section>
      </div>
    </section>
  )
}

const CoordinateSection = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">Timely messaging</h1>
        <p className="text-base font-semibold lg:text-2xl">Simple SMS based reminders for everyone waiting.</p>
        <p className="text-base font-semibold lg:text-2xl">Lets your guests enjoy, while we keep an eye on the charger.</p>
      </div>
      <div className="flex flex-col max-w-full mt-8 lg:max-w-screen-lg lg:mx-auto">
        <StaticImage 
          placeholder="tracedSVG"
          src="../images/community.png" 
          alt="We keep your customers informed and reminded"/>
      </div>
      <div className="py-10 text-black bg-relay-green-background lg:py-14">
        <section className="flex flex-col max-w-screen-lg mx-4 lg:mx-auto lg:flex-row">
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
                <h2 className="text-3xl font-bold text-relay-green-secondary">On availability</h2>
                <p className="my-2 text-base-lg">
                  As chargers become available, we send the wait list a notification so everyone
                  can charge up timely and the venue gets the most use out of the infrastructure.
                </p>
            </div>
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
              <h2 className="text-3xl font-bold text-relay-green-secondary">Staying too long?</h2>
                <p className="my-2 text-base-lg">
                  It's easy to lose sight of your car's status. We send gentle reminders so guests
                  can be considerate of others waiting to use the infrastructure.
                </p>
            </div>
            <div className="lg:w-1/3">
                <h2 className="text-3xl font-bold text-relay-green-secondary">Weighted wait list</h2>
                <p className="my-2 text-base-lg">
                  First come first serve, we remind the person who's next on the wait list. In case they
                  are unavailable to use the charger, we work through the list until we find the next
                  person and make sure no one loses their chance.
                </p>
            </div>
          </section>
      </div>
    </section>
  )
}

const KeepRollingSection = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">Keep them rolling</h1>
        <p className="text-base font-semibold lg:text-2xl">Guests chat while they cross paths swapping spots.</p>
        <p className="text-base font-semibold lg:text-2xl">Your infrastructure gets the most use.</p>
      </div>
      <div className="flex flex-col max-w-full mt-8 lg:max-w-screen-lg lg:mx-auto">
        <StaticImage 
          placeholder="tracedSVG"
          src="../images/friendly-02.png" 
          alt="We keep your customers informed and reminded"/>
      </div>
      <div className="py-10 text-black bg-relay-green-background lg:py-14">
        <section className="flex flex-col max-w-screen-lg mx-4 lg:mx-auto lg:flex-row">
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
                <h2 className="text-3xl font-bold text-relay-green-secondary">Social</h2>
                <p className="my-2 text-base-lg">
                  As drivers move their cars they get a chance to talk to each other as 
                  they are more likely to cross paths while using the infrastructure.
                </p>
            </div>
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
              <h2 className="text-3xl font-bold text-relay-green-secondary">Private</h2>
                <p className="my-2 text-base-lg">
                  We keep all the details securely stored so none of the guests have to exchange
                  details, making for a friendly experience for everyone.
                </p>
            </div>
            <div className="lg:w-1/3">
                <h2 className="text-3xl font-bold text-relay-green-secondary">Uniform</h2>
                <p className="my-2 text-base-lg">
                  Works across all mobile platforms because we are built upon the lowest
                  common denominator.
                </p>
            </div>
          </section>
      </div>
    </section>
  )
}

const ReportingSection = () => {
  return(
    <section className="mt-5 lg:mt-20">
      <div className="flex flex-col mx-4 lg:max-w-screen-md lg:mx-auto">
        <h1 className="mb-4 text-4xl font-extrabold lg:text-5xl text-relay-text">On the record</h1>
        <p className="text-base font-semibold lg:text-2xl">Gain visibility for the venue on the infrastructure use.</p>
        <p className="text-base font-semibold lg:text-2xl">Privately communicate with your guests while they charge.</p>
      </div>
      <div className="flex flex-col max-w-full mt-8 lg:max-w-screen-lg lg:mx-auto">
        <StaticImage 
          placeholder="tracedSVG"
          src="../images/reporting.png" 
          alt="We keep your customers informed and reminded"/>
      </div>
      <div className="py-10 text-black bg-relay-green-background lg:py-14">
        <section className="flex flex-col max-w-screen-lg mx-4 lg:mx-auto lg:flex-row">
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
                <h2 className="text-3xl font-bold text-relay-green-secondary">Charging history</h2>
                <p className="my-2 text-base-lg">
                  Keep a record of your visitors for customer service and in case of disasters.
                  Everything is securely stored and only accessible to people with permission.
                </p>
            </div>
            <div className="mb-8 lg:w-1/3 lg:mr-8 lg:mb-0">
              <h2 className="text-3xl font-bold text-relay-green-secondary">Reach out</h2>
                <p className="my-2 text-base-lg">
                  Reach out to guests if you are trying to resolve complex situations or just
                  to find out if everything is going to their expectations all without
                  revealing their personal details.
                </p>
            </div>
            <div className="lg:w-1/3">
                <h2 className="text-3xl font-bold text-relay-green-secondary">Reporting</h2>
                <p className="my-2 text-base-lg">
                  View statistics of usage and how your EV charging infrastructure is assisting
                  your venue gain new customers. Learn about your average time spent by a user, 
                  and what types of cars are visiting your venue.
                </p>
            </div>
          </section>
      </div>
    </section>
  )
}

const ForDestinationsPage = () => {
  return (
    <Layout>
      <Seo 
        title="For Destinations" 
        description="EV charging concierge for destinations"
      />
      <OnArrivalSection/>
      <CoordinateSection/>
      <KeepRollingSection/>
      <ReportingSection/>
    </Layout>
  )
}

export default ForDestinationsPage
